// Science 22 Logo
const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="30" viewBox="0 0 48 30">
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <path
            fill="#FF1D25"
            d="M36.253 16.286c.312-.312.596-.649.851-1.008l.417 1.044.253.633.676-.09 3.613-.476L48 15.605l-5.913-.95-2.477-.398-1.093-2.736c.243-2.354-.589-4.692-2.264-6.363-3.073-3.073-8.055-3.073-11.128 0l-1.089 1.088-1.088-1.088c-1.531-1.534-3.629-2.367-5.795-2.3l-.214-.553L16.046 0l-.758 2.353-.28.867c-2.217.702-4.01 2.349-4.898 4.499-.888 2.15-.78 4.582.296 6.644l-2.437.287L0 15.59l7.983.803 4.383.44 1.004 1.005v3.35h3.35l7.316 7.316 7.316-7.316h3.844v-3.844l1.057-1.057z"
            transform="translate(-16.000000, -15.000000) translate(16.000000, 15.750000)"
          />
          <path
            fill="#FFF"
            d="M23.68 18.3h-6.033l3.419-3.254c.828-.78 1.39-1.475 1.682-2.084.292-.61.442-1.28.438-1.956 0-1.134-.414-2.03-1.243-2.688-.828-.658-1.938-.987-3.327-.987-1.049 0-1.996.186-2.843.557-.847.372-1.52.893-2.02 1.564l1.663 1.28c.695-.89 1.694-1.335 2.999-1.335.78 0 1.37.158 1.773.475.402.317.603.774.603 1.371-.005.423-.108.84-.301 1.216-.201.409-.588.887-1.161 1.436l-5.083 4.826v1.59h9.434v-2.01z"
            transform="translate(-16.000000, -15.000000) translate(16.000000, 15.750000)"
          />
          <path
            fill="#FFF"
            d="M17.966 10.298l1.038 2.682c.416-.433.612-.729.7-.908.016-.032.027-.063.041-.094l-.635-1.64c-.229-.046-.462-.068-.695-.065-.15 0-.3.009-.45.025zM21.311 16.022L20.485 16.808 20.724 17.424 21.854 17.424zM13.602 10.441L12.119 15.043 8.071 15.52 12.814 15.997 14.405 11.059zM15.421 7.086c.1-.044.202-.084.304-.123l.432-1.34.415 1.073c.347-.085.7-.148 1.056-.186l-1.506-3.889-1.593 4.944c.284-.183.583-.343.892-.479zM34.32 18.3h-6.033l3.419-3.254c.828-.78 1.39-1.475 1.682-2.084.292-.61.442-1.28.438-1.956 0-1.134-.414-2.03-1.243-2.688-.828-.658-1.938-.987-3.327-.987-1.049 0-1.996.186-2.843.557-.847.372-1.52.893-2.02 1.564l1.663 1.28c.695-.89 1.694-1.335 2.999-1.335.78 0 1.37.158 1.773.475.402.317.603.774.603 1.371-.005.423-.108.84-.301 1.216-.201.409-.588.887-1.161 1.436l-5.083 4.826v1.59h9.434v-2.01z"
            transform="translate(-16.000000, -15.000000) translate(16.000000, 15.750000)"
          />
          <path
            fill="#FFF"
            d="M25.998 15.043L25.532 16.9 27.487 15.043zM38.98 15.043l-1.568-3.926-3.413 3.926h-1.052c-.193.207-.406.42-.637.638l-.333.316h2.457l2.672-3.075 1.229 3.075 3.614-.477-2.968-.477zM24.886 21.187L24.455 21.187 24.005 22.979 23.311 21.187 22.181 21.187 24.068 26.063 25.345 21.187z"
            transform="translate(-16.000000, -15.000000) translate(16.000000, 15.750000)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default Logo
