import Link from "next/link"
import { useRouter } from "next/router"
import { createContext, useState, useContext } from "react"
import Box from "@mui/material/Box"

export type SaveGuardState = {
  hasUnsavedState: boolean
  setHasUnsavedState: React.Dispatch<React.SetStateAction<boolean>>
  showConfirmDeployDialog: boolean
  setShowConfirmDeployDialog: React.Dispatch<React.SetStateAction<boolean>>
  showConfirmExitDialog: boolean
  setShowConfirmExitDialog: React.Dispatch<React.SetStateAction<boolean>>
  confirmDeployDialogCb: () => void
  setConfirmDeployDialogCb: React.Dispatch<React.SetStateAction<any>>
  confirmExitDialogCb: () => void
  setConfirmExitDialogCb: React.Dispatch<React.SetStateAction<any>>
}

export const SaveGuardContext = createContext<SaveGuardState>({
  hasUnsavedState: false,
  setHasUnsavedState: () => {},
  showConfirmDeployDialog: false,
  setShowConfirmDeployDialog: () => {},
  confirmDeployDialogCb: () => {},
  setConfirmDeployDialogCb: () => {},
  showConfirmExitDialog: false,
  setShowConfirmExitDialog: () => {},
  confirmExitDialogCb: () => {},
  setConfirmExitDialogCb: () => {},
})

export const SaveGuardProvider = ({ children }) => {
  const [hasUnsavedState, setHasUnsavedState] = useState(false)
  const [showConfirmExitDialog, setShowConfirmExitDialog] = useState(false)
  const [showConfirmDeployDialog, setShowConfirmDeployDialog] = useState(false)
  const [confirmDeployDialogCb, setConfirmDeployDialogCb] = useState(() => () => {})
  const [confirmExitDialogCb, setConfirmExitDialogCb] = useState(() => () => {})

  return (
    <SaveGuardContext.Provider
      value={{
        hasUnsavedState,
        setHasUnsavedState,
        showConfirmDeployDialog,
        setShowConfirmDeployDialog,
        showConfirmExitDialog,
        setShowConfirmExitDialog,
        confirmDeployDialogCb,
        setConfirmDeployDialogCb,
        confirmExitDialogCb,
        setConfirmExitDialogCb,
      }}
    >
      {children}
    </SaveGuardContext.Provider>
  )
}

export const SaveGuardLinkWrapper = ({ children, href }) => {
  const { hasUnsavedState, setConfirmExitDialogCb, setShowConfirmExitDialog } =
    useContext(SaveGuardContext)
  const router = useRouter()

  return (
    <Box
      onClick={() => {
        if (hasUnsavedState) {
          setConfirmExitDialogCb(() => () => router.push(href))
          setShowConfirmExitDialog(true)
        }
      }}
    >
      <Box sx={{ pointerEvents: hasUnsavedState ? "none" : "inherit" }}>
        <Link href={hasUnsavedState ? "" : href} style={{ color: "black" }}>
          {children}
        </Link>
      </Box>
    </Box>
  )
}
