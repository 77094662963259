import { Suspense as ReactSuspense } from "react"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"

const ProgressIndicator = () => (
  <Box mt={10} display="flex" justifyContent="center">
    <CircularProgress />
  </Box>
)

interface SuspenseProps {
  children: React.ReactNode
  hideProgressIndicator?: Boolean
}

const Suspense = ({ children, hideProgressIndicator }: SuspenseProps) => (
  <ReactSuspense fallback={hideProgressIndicator ? "" : <ProgressIndicator />}>
    {children}
  </ReactSuspense>
)

export default Suspense
