import Head from "next/head"
import Link from "next/link"
import { useMutation } from "@blitzjs/rpc"
import { Routes } from "@blitzjs/next"
import { ReactNode, useState } from "react"
import * as React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import AppBar from "@mui/material/AppBar"
import Drawer from "@mui/material/Drawer"
import MenuIcon from "@mui/icons-material/Menu"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Divider from "@mui/material/Divider"
import Avatar from "@mui/material/Avatar"
import { useCurrentUser } from "app/core/hooks/useCurrentUser"
import logout from "app/auth/mutations/logout"
import Suspense from "app/core/components/Suspense"
import Logo from "./Logo"
import { SaveGuardProvider, SaveGuardLinkWrapper } from "../context/SaveGuardContext"

type LayoutProps = {
  title?: string
  children: ReactNode
}

// --bittersweet: #f07167;
// --light-yellow: #fdfcdc;
// --very-light-grey: #f0f0f0;
// --bg-gray: #f7f8fa;
// --title-text: #646464;
// --peach-puff: #fed9b7;
// --verdigris: #00afb9;
// --body-supporting-text: #8e8e8e;
// --cg-blue: #0081a7;

const themeLight = createTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    subtitle1: { fontWeight: 600, color: "#798999" },
  },
  palette: {
    background: {
      default: "#f7f8fa",
    },
    primary: {
      main: "#0081a7",
    },
  },
})

const UserButton = ({ menuId, handleProfileMenuOpen }) => {
  const currentUser = useCurrentUser()
  if (!currentUser) return null
  return (
    <IconButton
      size="large"
      edge="end"
      aria-label="account of current user"
      aria-controls={menuId}
      aria-haspopup="true"
      onClick={handleProfileMenuOpen}
      color="inherit"
    >
      <Avatar alt={currentUser.name || undefined} src={currentUser.avatarUrl || undefined} />
    </IconButton>
  )
}

const MenuProfileItem = () => {
  const currentUser = useCurrentUser()
  if (!currentUser) return <div>...</div>
  return (
    <MenuItem disabled={true}>
      {currentUser.name}
      <br />
      {currentUser.role}
    </MenuItem>
  )
}

const MenuLogoutItem = ({}) => {
  const [logoutMutation] = useMutation(logout)
  return (
    <MenuItem
      onClick={async () => {
        await logoutMutation()
      }}
    >
      Logout
    </MenuItem>
  )
}

const UserMenu = ({ menuId, anchorEl, isMenuOpen, handleMenuClose }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuProfileItem />
      <Divider />
      <MenuLogoutItem />
    </Menu>
  )
}

const MenuNavItem = ({ children, href, onClick }) => {
  return (
    <SaveGuardLinkWrapper href={href}>
      <MenuItem onClick={onClick}>{children}</MenuItem>
    </SaveGuardLinkWrapper>
  )
}

const ButtonNavItem = ({ children, href }) => {
  return (
    <SaveGuardLinkWrapper href={href}>
      <Button color="inherit" sx={{ textTransform: "none" }}>
        {children}
      </Button>
    </SaveGuardLinkWrapper>
  )
}

const Layout = ({ title, children }: LayoutProps) => {
  const [drawerIsOpen, setDrawerIsOpen] = React.useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuId = "user-menu"
  const isMenuOpen = Boolean(anchorEl)
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <ThemeProvider theme={themeLight}>
      <SaveGuardProvider>
        <CssBaseline />
        <Head>
          <title>{title || "Pipe Platform v3"}</title>
          <link rel="icon" href="/favicon.ico" />
        </Head>

        <Box>
          <AppBar position="static" color={"transparent"} sx={{ background: "white" }}>
            <Toolbar sx={{ display: { xs: "flex", lg: "none" } }}>
              <Box sx={{ flexGrow: 1 }}>
                <Logo />
              </Box>
              <IconButton onClick={() => setDrawerIsOpen(true)}>
                <MenuIcon />
              </IconButton>
              <Drawer anchor="right" open={drawerIsOpen} onClose={() => setDrawerIsOpen(false)}>
                <Suspense hideProgressIndicator>
                  <MenuNavItem onClick={() => setDrawerIsOpen(false)} href={Routes.PipelinesPage()}>
                    Pipelines
                  </MenuNavItem>
                  <MenuNavItem onClick={() => setDrawerIsOpen(false)} href={Routes.OrdersPage()}>
                    Orders
                  </MenuNavItem>
                  <MenuNavItem onClick={() => setDrawerIsOpen(false)} href={Routes.AnalyticsPage()}>
                    Analytics
                  </MenuNavItem>
                  <MenuNavItem onClick={() => setDrawerIsOpen(false)} href={Routes.AbTestPage()}>
                    AB Tests
                  </MenuNavItem>
                  <MenuNavItem
                    onClick={() => setDrawerIsOpen(false)}
                    href={Routes.DefaultPipesPage()}
                  >
                    Default pipes
                  </MenuNavItem>
                  <MenuNavItem
                    onClick={() => setDrawerIsOpen(false)}
                    href={Routes.EmailTemplatesPage()}
                  >
                    Email Templates
                  </MenuNavItem>
                  <MenuNavItem onClick={() => setDrawerIsOpen(false)} href={Routes.BrandsPage()}>
                    Brands
                  </MenuNavItem>
                  <MenuNavItem
                    onClick={() => setDrawerIsOpen(false)}
                    href={Routes.GeographiesPage()}
                  >
                    Geographies
                  </MenuNavItem>
                  <MenuNavItem onClick={() => setDrawerIsOpen(false)} href={Routes.CountriesPage()}>
                    Countries
                  </MenuNavItem>
                  <MenuNavItem onClick={() => setDrawerIsOpen(false)} href={Routes.LanguagesPage()}>
                    Languages
                  </MenuNavItem>
                  <MenuNavItem
                    onClick={() => setDrawerIsOpen(false)}
                    href={Routes.CurrenciesPage()}
                  >
                    Currencies
                  </MenuNavItem>
                  <MenuNavItem onClick={() => setDrawerIsOpen(false)} href={Routes.UsersPage()}>
                    Admins
                  </MenuNavItem>
                  <Divider />
                  <MenuProfileItem />
                  <MenuLogoutItem />
                </Suspense>
              </Drawer>
            </Toolbar>
            <Toolbar sx={{ display: { xs: "none", lg: "flex" } }}>
              <Box sx={{ flexGrow: 1 }}>
                <Logo />
              </Box>
              <ButtonNavItem href={Routes.PipelinesPage()}>Pipelines</ButtonNavItem>
              <ButtonNavItem href={Routes.OrdersPage()}>Orders</ButtonNavItem>
              <ButtonNavItem href={Routes.AnalyticsPage()}>Analytics</ButtonNavItem>
              <ButtonNavItem href={Routes.AbTestPage()}>AB Tests</ButtonNavItem>
              <ButtonNavItem href={Routes.DefaultPipesPage()}>Default Pipes</ButtonNavItem>
              <ButtonNavItem href={Routes.EmailTemplatesPage()}>Email Templates</ButtonNavItem>
              <ButtonNavItem href={Routes.BrandsPage()}>Brands</ButtonNavItem>
              <ButtonNavItem href={Routes.GeographiesPage()}>Geographies</ButtonNavItem>
              <ButtonNavItem href={Routes.CountriesPage()}>Countries</ButtonNavItem>
              <ButtonNavItem href={Routes.LanguagesPage()}>Languages</ButtonNavItem>
              <ButtonNavItem href={Routes.CurrenciesPage()}>Currencies</ButtonNavItem>
              <ButtonNavItem href={Routes.DeploymentsPage()}>Deployments</ButtonNavItem>
              <ButtonNavItem href={Routes.UsersPage()}>Admins</ButtonNavItem>
              <Suspense hideProgressIndicator>
                <UserButton menuId={menuId} handleProfileMenuOpen={handleProfileMenuOpen} />
              </Suspense>
            </Toolbar>
          </AppBar>
          <Suspense hideProgressIndicator>
            <UserMenu
              menuId={menuId}
              anchorEl={anchorEl}
              isMenuOpen={isMenuOpen}
              handleMenuClose={handleMenuClose}
            />
          </Suspense>
        </Box>
        {children}
      </SaveGuardProvider>
    </ThemeProvider>
  )
}

export default Layout
